/* eslint-disable no-param-reassign */
import getMoment from 'utils/getMoment';
import removeEqualProperties from 'utils/removeEqualProperties';

const addEmployeeLabel = messages => {
  let prev = null;
  return messages.map(message => {
    const getName = () => {
      if (!message.employee) message.employee_id = 'Deleted User';

      if (message.isSuperAdmin) message.employee_id = 'Super Admin';

      if (message.isSuperAdmin) return 'Super admin';
      const name =
        `${message.from?.firstName || ''} ${message.from?.surName || ''}`.trim() ||
        message.employee.name ||
        `${message.employee?.firstName || ''} ${message.employee?.surName || ''}`.trim();
      return message.from?._id || message.employee?._id ? name : 'Deleted User';
    };
    const prevMessage = prev;
    prev = message;

    return {
      ...message,
      employeeName: getName(),
      employeeLabel:
        !message.dateTime &&
        prevMessage &&
        prevMessage.employee?._id === message.employee?._id &&
        prevMessage.sentAsChannel === message.sentAsChannel
          ? ''
          : getName(),
    };
  });
};

const groupAndSortMessages = messages => {
  let msgs = messages;
  msgs = msgs.sort((a, b) => {
    if (!a.createdAt) return -1;
    if (!b.createdAt) return 1;
    return getMoment(a.createdAt).valueOf() - getMoment(b.createdAt).valueOf();
  });
  msgs = removeEqualProperties(msgs, 'dateTime');
  msgs = addEmployeeLabel(msgs);

  return msgs;
};

export default groupAndSortMessages;
